.App {
  text-align: center;
}

.App-fractal {
  margin-bottom: 30px;
}

.App-header {
  min-height: 85vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.App button {
  margin: 0 0.3em;
}
