.NotFound {
  text-align: center;
  padding: 10vh 10vw;
}

.NotFound .anticon {
  color: rgba(0, 0, 0, 0.85);
  font-size: 60px;
  margin: 10px;
}
