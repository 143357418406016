.EditableTitle {
  color: rgba(0, 0, 0, 0.85);
  font-size: 3em;
  font-weight: 500;
  margin-bottom: 0.3em;
}

.EditableTitle-error {
  color: #ff4136;
}

.EditableTitle input {
  line-height: 0;
  font-weight: 500;
  width: 100%;
}

@media (max-width: 750px) {
  .EditableTitle {
    font-size: 2em;
  }
}
