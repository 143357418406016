body {
  margin: 0;
  padding: 0;
}

h1 {
  font-size: 3em;
  margin-bottom: 0em;
}

.ant-skeleton-title {
  height: 3em !important;
}

p {
  font-size: 1.7em;
}

@media (max-width: 750px) {
  h1 {
    font-size: 2em;
  }

  .ant-skeleton-title {
    height: 2em !important;
  }

  p {
    font-size: 1.3em;
  }
}

canvas {
  /* border-radius: 4px;
     border: 1px solid transparent;
     box-shadow: 0 2px 0 rgba(0, 0, 0, 0.015);
     border-color: #d9d9d9; */
}

.App {
  text-align: center;
}

.App-fractal {
  margin-bottom: 30px;
}

.App-header {
  min-height: 85vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.App button {
  margin: 0 0.3em;
}

nav {
  display: flex;
  border-bottom: 1px solid rgb(228, 228, 228);
  padding: 0.75em 1.5em;
  font-size: 1.2em;
}

nav .home {
  color: rgba(0, 0, 0, 0.9);
  font-weight: 500;
  margin-right: 1em;
}

nav > a {
  color: rgba(0, 0, 0, 0.5);
  margin-right: 0.7em;
}

nav > a:hover {
  color: rgba(0, 0, 0, 0.9);
}

.Card {
  padding: 30px;
}

.Card-attractor {
  margin-bottom: 0.5em;
}

.Browse-body {
  margin: 0 auto;
  padding: 2em;
  max-width: 1000px;
}

.Browse-header {
  display: flex;
}

.Browse-header button:last-child {
  margin-left: auto;
  margin-top: 15px;
}

@media (max-width: 750px) {
  .Browse-header button:last-child {
    margin-top: 5px;
  }
}

.RedTooltip {
  max-width: none;
}

.RedTooltip .ant-tooltip-arrow {
  border-bottom-color: #ff4136;
}

.RedTooltip .ant-tooltip-inner {
  background-color: #ff4136;
}

.EditableTitle {
  color: rgba(0, 0, 0, 0.85);
  font-size: 3em;
  font-weight: 500;
  margin-bottom: 0.3em;
}

.EditableTitle-error {
  color: #ff4136;
}

.EditableTitle input {
  line-height: 0;
  font-weight: 500;
  width: 100%;
}

@media (max-width: 750px) {
  .EditableTitle {
    font-size: 2em;
  }
}

.RenderMethodSelect {
  text-transform: capitalize;
}

.SettingsDrawer-label {
  text-align: right;
  padding: 5px 10px;
}

.SettingsDrawer-label::after {
  content: ':';
}

.SettingsDrawer-row {
  padding-bottom: 5px;
}

.TourTooltip {
  padding: 16px 24px;
  background-color: white;
  border-radius: 4px;
  width: 384px;
  max-width: calc(100vw - 32px);
}

.TourTooltip-buttons {
  align-items: center;
  display: flex;
  justify-content: flex-end;
  margin-top: 16px;
}

.TourTooltip button {
  margin-left: 5px;
}

.YesNo button {
  margin-left: 5px;
}

.Create-body {
  padding: 2em 3em;
}

.Create .ant-tabs-bar {
  margin: 0;
}

.NotFound {
  text-align: center;
  padding: 10vh 10vw;
}

.NotFound .anticon {
  color: rgba(0, 0, 0, 0.85);
  font-size: 60px;
  margin: 10px;
}

.View-body {
  margin: 0 auto;
  padding: 2em;
  max-width: 1000px;
}

.View-header {
  display: flex;
}

.View-title h1 {
  margin-bottom: 5px;
}

.View-title p {
  font-size: 1.3em;
}

.View-header button:last-child {
  margin-left: auto;
  margin-top: 20px;
}

@media (max-width: 750px) {
  .View-header button:last-child {
    margin-top: 10px;
  }

  .View-title p {
    font-size: 1em;
  }
}

.View .hide {
  display: none;
}

