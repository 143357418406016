.TourTooltip {
  padding: 16px 24px;
  background-color: white;
  border-radius: 4px;
  width: 384px;
  max-width: calc(100vw - 32px);
}

.TourTooltip-buttons {
  align-items: center;
  display: flex;
  justify-content: flex-end;
  margin-top: 16px;
}

.TourTooltip button {
  margin-left: 5px;
}
