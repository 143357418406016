.Browse-body {
  margin: 0 auto;
  padding: 2em;
  max-width: 1000px;
}

.Browse-header {
  display: flex;
}

.Browse-header button:last-child {
  margin-left: auto;
  margin-top: 15px;
}

@media (max-width: 750px) {
  .Browse-header button:last-child {
    margin-top: 5px;
  }
}
