body {
  margin: 0;
  padding: 0;
}

h1 {
  font-size: 3em;
  margin-bottom: 0em;
}

.ant-skeleton-title {
  height: 3em !important;
}

p {
  font-size: 1.7em;
}

@media (max-width: 750px) {
  h1 {
    font-size: 2em;
  }

  .ant-skeleton-title {
    height: 2em !important;
  }

  p {
    font-size: 1.3em;
  }
}
