.View-body {
  margin: 0 auto;
  padding: 2em;
  max-width: 1000px;
}

.View-header {
  display: flex;
}

.View-title h1 {
  margin-bottom: 5px;
}

.View-title p {
  font-size: 1.3em;
}

.View-header button:last-child {
  margin-left: auto;
  margin-top: 20px;
}

@media (max-width: 750px) {
  .View-header button:last-child {
    margin-top: 10px;
  }

  .View-title p {
    font-size: 1em;
  }
}

.View .hide {
  display: none;
}
